import styled from 'styled-components'

const Text = styled.p`
  margin: 24px 0;
  font-size: 18px;
  line-height: normal;
  text-align: justify;

  @media (min-width: 576px) {
    font-size: 24px;
  }
`

export default Text
