import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import Text from '../components/Text';

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContactPage = () => (
  <Layout title="Contact me">
    <Content>
      <Text>
        You can find me on <a href="http://twitter.com/sfrostwick">Twitter</a>{' '}
        or <a href="https://www.linkedin.com/in/stevenfrostwick/">LinkedIn</a>.
      </Text>

      <StaticImage
        alt="Steven Frostwick scuba diving"
        src={'../images/steven-frostwick-scuba-diving.jpg'}
        title="Steven Frostwick scuba diving"
      />
    </Content>
  </Layout>
);

export default ContactPage;
